import React, { useEffect } from 'react';
import styled from 'styled-components'
import { animated, config, useSpring } from 'react-spring';

const StyledConfettiDot = styled.svg`
  position: absolute;
  will-change: transform;
`;

const AnimatedConfettiDot = animated(StyledConfettiDot);

const Dot = (props) => {
  // const { scale } = useSpring({
  //   config: { duration: 1500 }, 
  //   loop: { reverse: true },
  //   from: { scale: 0.9 },
  //   enter: { scale: Math.random() * 0.2 + 0.9 },
  //   to: { scale: 1.1 }
  // });

  // const { scale } = useSpring({
  //   config: { duration: 5 }, 
  //   from: { scale: 0 },
  //   enter: { scale: 0 },
  //   to: { scale: 1 }
  // });
  // console.log(scale);

  return (
    <circle 
      // style={{
      //   transformOrigin: `${props.x}px ${props.y}px`, 
      //   transform: scale.to(s => `scale(${s})`)
      // }} 
      id={props.id}
      // visibility={props.viz ? 'visible' : 'hidden'}
      cx={props.x} cy={props.y} r={props.r} fill={props.color || 'rgba(0, 0, 0, 0.87)'}>
        {/* <animate attributeName="r" from="0" to ={props.r} begin="500ms" dur="1000ms"/> */}
    </circle>
  )
};

const LAT_DOT_COUNT = 45;
const LNG_DOT_COUNT = 90;

const Dots = () => {

  const latScale = LAT_LAND_COUNT / LAT_DOT_COUNT;
  const lngScale = LNG_LAND_COUNT / LNG_DOT_COUNT;
  const [renderRowMax, setRenderRowMax] = React.useState(155);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (renderRowMax < LAT_DOT_COUNT) {
        setRenderRowMax(renderRowMax + 2);
      } else {
        clearInterval(intervalId);
      }
    }, 1)
  
    return () => clearInterval(intervalId); //This is important
  }, [renderRowMax])

  return (
    // <AnimatedConfettiDot
    //   style={{
    //     //scale: y.to(yValue => yValue),
    //     // transform: y.to(yValue => `translate3d(0,${yValue}px,0)`),
    //     transform: y.to(yValue => `translate3d(0,${yValue}px,0)`),
    //     // transform: scale.to(s => `scale(${s})`)
    //     // transform: y.to(yValue => `scale(${yValue})`)
    //   }}
    // >
    <svg viewBox="0 0 455 250" style={{width: '100%'}}>
      {[...Array(LAT_DOT_COUNT)].map((_, lat) => 
        [...Array(LNG_DOT_COUNT)].map((_, long) => {
          var r = 0;
          for (var i = 0; i < latScale; ++i)
          for (var j = 0; j < lngScale; ++j)
            r += LAND_INFO[lat*latScale + i][long*lngScale + j];
          r = r * 1.0 / (latScale * lngScale);

          if (r > 0 && lat < renderRowMax)
            return (<Dot viz={lat < renderRowMax} key={lat * 180 + long} x={long*5 + 2 + (lat % 2 == 0 ? 2.5 : 0)} y={lat*5 + 3} r={r*1.75}/>)
        })
      )}
      {VISITED_IDX.map( (coord, i) => {
        var lat = coord[0]
        var long = coord[1]

        if (lat < renderRowMax)
          return (
            <Dot viz={lat < renderRowMax} key={100000*i + lat * 180 + long} x={long*5 + 2 + (lat % 2 == 0 ? 2.5 : 0)} y={lat*5 + 3} r={2.25} id='visited' color='#BD1E51'/>
          )
      })}
    </svg>
    // </AnimatedConfettiDot>
  );
};

const coordToIdx = (lat, lng) => {
  var latIdx = Math.round(((90-lat) * 1.0 / 180) * LAT_DOT_COUNT)
  var lngIdx = Math.round(((lng + 180) * 1.0 / 360) * LNG_DOT_COUNT)
  return [latIdx, lngIdx]
}

const VISITED_IDX = [
  coordToIdx(47.67854962436138, -122.3217408868987), // seattle
  coordToIdx(49.29190893828228, -123.25150289358764), // vancouver
  coordToIdx(44.09547562639155, -79.25536977157877),  // toronto
  coordToIdx(34.44628738314905, -118.62234575557122),  // LA
  coordToIdx(38.08072145292137, -122.3966182369829),  // SF
  coordToIdx(30.514161890476966, -95.24777873463812),  // Houston
  coordToIdx(40.87207864914368, -74.00133748090168),  // NYC
  coordToIdx(41.170752206343785, 28.94236911574848),  // Istanbul
  coordToIdx(51.58234555938976, -0.13938196651008225),  // London
  coordToIdx(52.386523770383434, 4.895328632848979),  // Amsterdam
  coordToIdx(48.87533878333612, 2.3299642357167394),  // Paris
  coordToIdx(47.475785202353904, 19.004832982586763),  // Budapest
  coordToIdx(48.11550316439622, 11.619693051448504),  // Munich
  coordToIdx(50.04998007203866, 14.47657613007304),  // Prague
  coordToIdx(52.61131805375761, 13.388239719168455),  // Berlin
  coordToIdx(49.447230193334896, 11.056090267230056),  // Nurenberg
  coordToIdx(48.200938601210716, 16.392728099918834),  // Viyana
  coordToIdx(18.44959885394528, -66.12127231851535),  // San Juan
  coordToIdx(21.289323803276506, -157.85524009016783),  // Honolulu
]

const LAND_INFO = [[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.25,0.25,0.25,0.25,0.125,0.0,0.0,0.125,0.125,0.25,0.3125,0.5,0.4375,0.25,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.1875,0.0625,0.25,0.125,0.625,0.75,0.6875,0.75,0.875,0.75,0.625,0.6875,0.8125,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9375,0.875,0.3125,0.25,0.0,0.0,0.0,0.0,0.0,0.125,0.25,0.375,0.375,0.125,0.0,0.0,0.0,0.0,0.0625,0.125,0.0625,0.125,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.375,0.3125,0.25,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.1875,0.4375,0.4375,0.25,0.125,0.3125,0.5625,0.4375,0.5625,0.6875,0.25,0.0,0.125,0.375,0.5,0.75,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.875,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.125,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.25,0.1875,0.1875,0.0625,0.0,0.0,0.0,0.125,0.375,0.5,0.5,0.75,0.8125,0.6875,0.25,0.0,0.0,0.0,0.0,0.0,0.1875,0.25,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.125,0.0,0.0,0.0,0.0,0.1875,0.25,0.0625,0.0,0.0,0.0,0.0,0.0,0.0625,0.6875,0.625,0.5,0.6875,0.875,0.3125,0.4375,0.8125,0.375,0.875,1.0,0.875,0.5,0.25,0.0,0.0,0.0,0.8125,0.9375,1.0,1.0,1.0,1.0,0.9375,0.6875,0.5,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.1875,0.125,0.0,0.0,0.0,0.0,0.0,0.5,0.0625,0.0,0.0625,0.6875,0.375,0.5,0.8125,1.0,1.0,1.0,1.0,1.0,0.9375,0.875,0.9375,0.9375,0.8125,0.875,0.4375,0.375,0.4375,0.875,0.625,0.4375,0.25,0.25,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.625,0.3125,0.0625,0.1875,0.6875,1.0,1.0,1.0,1.0,1.0,0.8125,0.8125,1.0,1.0,0.875,0.8125,0.9375,0.6875,0.9375,0.5625,0.75,0.6875,0.875,0.5,0.5,0.1875,0.5625,1.0,0.5,0.125,0.0,0.4375,0.8125,1.0,1.0,1.0,0.75,0.5,0.25,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.25,0.8125,1.0,1.0,1.0,0.625,0.625,0.125,0.375,0.5,0.625,0.6875,1.0,0.75,0.8125,0.875,0.9375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9375,0.8125,1.0,0.875]
  ,[
  0.1875,0.375,0.125,0.3125,0.625,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9375,0.5,0.375,0.25,0.25,0.1875,0.6875,0.6875,0.0,0.0,0.0,0.8125,1.0,0.75,0.0,0.0,0.0,0.0625,0.25,0.625,0.25,0.0,0.0,0.0,0.0,0.0625,0.5,0.9375,0.8125,0.5,0.9375,1.0,0.875,0.6875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9375,0.9375,1.0,1.0,0.625]
  ,[
  0.0,0.0,0.0,0.1875,0.625,0.8125,0.8125,0.5,0.375,0.5,0.8125,0.9375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.4375,0.0,0.0,0.0,0.5,1.0,0.5,0.25,0.0625,0.0,0.0,0.0625,0.375,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.125,0.0,0.6875,0.8125,1.0,0.375,0.375,0.8125,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.8125,0.75,0.5625,0.625,0.125,0.625,0.5,0.375,0.0625,0.0]
  ,[
  0.0,0.0,0.0,0.0625,0.125,0.1875,0.1875,0.0,0.0,0.0,0.0,0.4375,0.8125,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.75,0.375,0.125,0.4375,1.0,1.0,1.0,0.875,0.1875,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.5,0.5,0.0,0.0,0.5,0.5,0.375,0.6875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.4375,0.0,0.0,0.0,0.0,0.9375,0.5625,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.25,0.9375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.75,0.875,1.0,1.0,1.0,1.0,0.8125,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.25,0.3125,0.6875,0.3125,0.75,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.4375,0.0,0.0,0.0,0.5625,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.9375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9375,0.6875,0.25,0.25,0.3125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.5625,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9375,1.0,0.875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.8125,0.375,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.6875,0.4375,0.1875,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.4375,0.5,0.9375,0.6875,0.75,0.5625,0.875,1.0,0.9375,0.25,0.1875,0.25,0.875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9375,0.6875,0.0625,0.3125,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.9375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9375,0.5625,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.0,0.0,0.0,0.0,0.25,1.0,1.0,0.25,0.0,0.25,0.25,0.3125,0.8125,0.5625,0.6875,1.0,0.75,0.875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.8125,0.5625,0.875,0.1875,0.0,0.0,0.375,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.4375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9375,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.5625,0.5,0.6875,0.75,0.5,0.125,0.0,0.125,0.0625,0.375,0.375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9375,0.125,0.4375,0.25,0.375,0.625,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.25,0.8125,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.8125,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.0,0.3125,1.0,1.0,1.0,1.0,0.9375,0.6875,0.1875,0.625,0.375,0.25,0.5,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.25,0.0625,0.25,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.4375,0.8125,1.0,1.0,0.875,0.125,0.0625,0.0,0.5625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.1875,0.8125,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.6875,1.0,1.0,1.0,0.3125,0.6875,0.9375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.25,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.25,0.8125,1.0,0.5625,0.0,0.0,0.0,0.25,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.8125,0.5,1.0,1.0,0.8125,0.625,0.5625,0.25,0.25,0.875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9375,0.4375,0.1875,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.1875,1.0,0.8125,0.125,0.5625,0.125,0.0,0.1875,0.25,0.4375,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.375,0.875,1.0,1.0,1.0,0.5625,0.0,0.0,0.125,0.75,1.0,1.0,0.4375,0.0,0.6875,1.0,1.0,0.6875,0.375,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.4375,0.5625,1.0,0.5,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.875,0.4375,1.0,0.8125,0.25,0.0,0.0,0.0,0.0,0.625,1.0,0.3125,0.0,0.0,0.1875,0.75,1.0,0.9375,0.125,0.0,0.0,0.4375,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.6875,0.0625,0.0,0.3125,0.25,0.125,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.8125,0.375,0.25,0.0625,0.0,0.0,0.0,0.0,0.125,1.0,0.0,0.0,0.0,0.0,0.375,0.4375,1.0,0.1875,0.0,0.0625,0.375,0.1875,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.4375,0.375,1.0,0.9375,1.0,0.9375,0.1875,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.25,0.9375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.5,0.0,0.0,0.0,0.0,0.0,0.0,0.25,0.375,0.0,0.0,0.0,0.25,0.125,0.0625,0.0,0.0,0.25,0.0625,0.5,0.0,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.4375,1.0,1.0,1.0,1.0,1.0,0.9375,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.3125,0.25,0.0,0.3125,0.6875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.8125,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.375,0.5625,0.0,0.0625,0.625,0.625,0.0,0.0625,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.9375,1.0,1.0,1.0,1.0,1.0,1.0,0.625,0.1875,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.6875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.6875,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.8125,0.0625,0.6875,1.0,0.5,0.375,0.125,0.1875,0.1875,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.1875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.8125,0.4375,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.1875,1.0,1.0,1.0,1.0,1.0,1.0,0.875,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.375,0.5,0.125,0.375,0.25,0.25,0.0625,0.0625,0.3125,0.875,0.8125,0.1875,0.25,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.8125,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.1875,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.8125,1.0,1.0,1.0,1.0,1.0,0.8125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.1875,0.3125,0.125,0.3125,0.1875,0.125,0.0625,0.0625,0.1875,0.6875,0.625,0.125,0.0625,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.25,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.625,1.0,1.0,1.0,1.0,1.0,1.0,0.125,0.0,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.25,0.5625,0.0625,0.25,0.0,0.0625,0.0625,0.0,0.0625,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.5,0.9375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.25,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,1.0,1.0,1.0,1.0,1.0,1.0,0.875,0.125,0.5625,0.4375,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.1875,0.875,0.9375,1.0,0.4375,0.6875,0.3125,0.0,0.0,0.0,0.0,0.0625,0.0,0.0,0.0625]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.5,1.0,1.0,1.0,1.0,1.0,1.0,0.9375,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.75,1.0,1.0,1.0,1.0,0.8125,0.0625,0.0625,1.0,0.1875,0.0,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.375,0.8125,1.0,1.0,1.0,1.0,1.0,0.875,0.125,0.0,0.0,0.0,0.125,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.625,1.0,1.0,1.0,1.0,0.9375,0.5,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.375,1.0,1.0,1.0,1.0,0.6875,0.0,0.0625,0.75,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.5625,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.8125,0.0625,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.75,1.0,1.0,1.0,1.0,0.75,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.9375,1.0,1.0,0.9375,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.4375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.375,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.9375,1.0,1.0,0.9375,0.9375,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.5625,1.0,0.9375,0.375,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,1.0,1.0,0.625,0.5,0.6875,0.9375,1.0,1.0,0.9375,0.125,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.25,1.0,1.0,1.0,0.75,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.1875,0.0,0.0,0.0,0.0625,0.3125,1.0,1.0,0.5625,0.0,0.0,0.0,0.0,0.0,0.1875,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.4375,1.0,0.9375,0.5,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.3125,0.0,0.0,0.0,0.0,0.0,0.0,0.4375,0.25]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.5,1.0,0.6875,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.25,0.0,0.0,0.0,0.0,0.0625,0.5625,0.1875,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.8125,1.0,0.25,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.5625,0.6875,0.0,0.0,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.25,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.25,0.1875,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.25,0.625,0.1875,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.0,0.4375,0.625,0.8125,1.0,0.8125,0.5625,0.5,0.1875,0.0,0.375,0.5625,0.75,0.75,0.75,0.8125,1.0,0.9375,0.8125,0.9375,0.75,0.75,0.875,0.875,1.0,0.875,0.75,0.5,0.4375,0.375,0.1875,0.0,0.0,0.0,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.0,0.0,0.0,0.0,0.0,0.0,0.4375,0.3125,0.25,0.1875,0.0625,0.25,0.3125,0.8125,0.9375,0.625,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0625,0.25,0.6875,0.75,0.6875,0.75,0.75,0.875,1.0,0.9375,0.75,0.75,0.9375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.6875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.875,0.6875,0.25,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.0,0.125,0.25,0.125,0.4375,0.625,0.75,0.75,0.8125,0.8125,0.75,0.8125,0.875,0.875,0.75,0.8125,1.0,1.0,1.0,1.0,1.0,0.875,0.875,0.625,0.5,0.1875,0.0,0.0,0.0,0.0,0.0,0.0,0.125,0.3125,0.5,0.5625,0.8125,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.8125,0.25,0.0625,0.0,0.0]
  ,[
  0.0,0.0,0.0,0.0,0.25,0.0625,0.25,0.8125,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.875,0.9375,0.25,0.375,0.1875,0.0,0.0,0.125,0.5,0.625,0.1875,0.25,0.5625,0.9375,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.4375,0.1875,0.0,0.0,0.0]
  ,[
  0.5,0.5,0.25,0.25,0.25,0.6875,0.8125,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.8125,0.75,1.0,1.0,0.875,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.875,0.75,0.5,0.5]
  ,[
  1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0]
]

const LAT_LAND_COUNT = LAND_INFO.length;
const LNG_LAND_COUNT = LAND_INFO[0].length;

export default Dots;